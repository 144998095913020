<template>
  <div class="newDownMobile">
    <div class="top_image">
      <img class="img_all_logo lazyloaded"
           :src="`${imgUrl}/img/new_all_logo1.png`"
           alt="" />
    </div>

    <van-swipe :autoplay="3000"
               indicator-color="#d2b596">
      <van-swipe-item v-for="(image, index) in images"
                      :key="index">
        <img class="swiperImg"
             :src="image.img" />
      </van-swipe-item>
    </van-swipe>
    <div class="new_all_logo">
      <img :src="`${imgUrl}/img/new_all_logo.png`" />
    </div>
    <div id="progress"
         v-if="!isDisabled">
      <div class="load_pic">
        <span id="downText"
              @click="getProgress">{{ downapptitle }}</span>
      </div>
    </div>
    <div id="progress"
         v-else>
      <van-circle v-model="currentRate"
                  :rate="0"
                  :speed="100"
                  :text="text"
                  :size="45"
                  layer-color="#d7d7d7"
                  color="#347AFD"
                  :stroke-width="60" />
      <div class="load_pic"
           style="background:#83b8ed;width:260px">
        <span id="downText">{{ downapptitle }}</span>
      </div>
    </div>
    <div class="href_text"
         @click="goToWebApp">
      若无法下载安装，点击此处跳转网页版
    </div>

    <div class="warningText_new">
      为了您的隐私安全，请勿与"国家反诈中心"APP同设备使用
    </div>
    <!-- <div class="baodu-box" @click="goTosetting" v-if="!isiOS">
      <img src="../assets/img/baodu.png" style="width:100px" />
    </div> -->

    <div class="down_wrapper"
         v-if="yourPhone === 2">
      <p class="box">下滑查看安装教程</p>
      <i class="down_l_new"></i>
    </div>
    <img class="torial_step"
         :src="`${imgUrl}/img/img_step_ios.png`"
         alt=""
         v-if="yourPhone === 2" />

    <div class="container"
         v-if="showpopup && !isiOS">
      <div class="cBox5">
        <div class="popup-title">安装时有报毒提示怎么办</div>
        <div class="popup-content">
          <div class="popup-content-title">
            由于国内网络环境原因，有部分安卓手机安装体育类的软件时会有报毒提示，<span style="color:red">属正常状态请会员放心 安装使用!</span>
          </div>
        </div>
        <div class="popup-content">
          <div class="popup-content-title">
            请认定贝宝官方网址：
            <div>
              <span style="margin-right: 20px;">bebao.bet </span>
              <span>bevip.bet </span>
            </div>
          </div>
        </div>

        <div style="width:80%;margin: 0 auto;">
          <img :src="`${imgUrl}/img/tanchuang.png`"
               style="width:100%;min-height: 100px;" />
        </div>

        <div class="btn-box"
             @click="showpopup = false">
          <div class="btnfuzhi">我已知晓</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { appdownloadlink, speed, dowmapp } from '../network/api'

export default {
  name: '',
  components: {},
  data () {
    return {
      images: [
        {
          img: `${this.imgUrl}/img/img_download_material_1.png`
        }
        // {
        //   img: `${this.imgUrl}/img_download_material_2.png`
        // },
        // {
        //   img: `${this.imgUrl}/img_download_material_3.png`
        // },
        // {
        //   img: `${this.imgUrl}/img_download_material_4.png`
        // }
      ],
      yourPhone: '', // 1 安卓   2 ios
      url: '',
      webUrl: '',
      isShareTrace: false,
      code: '',
      isiOS: true,
      showpopup: true,
      currentRate: 0,
      downapptitle: '立即下载',
      isDisabled: false,
      iosdownipa: 'https://gz.bcebos.com/sabbont/ipa/be_aidao.ipa'
    }
  },
  computed: {
    text () {
      return this.currentRate.toFixed(0) + '%'
    }
  },
  mounted () {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    this.isiOS = isiOS
    if (isiOS) {
      this.yourPhone = 2
    } else {
      this.yourPhone = 1
    }
    this.getUrl()
    this.speed()
    window.onresize = () => { }
  },
  created () {
    let _this = this

    if (window.location.host == 'be116.app') {
      _this.code = 'a_code=V-1266'
    } else if (window.location.host == 'be771.app') {
      _this.code = 'a_code=V-6627'
    } else {
      _this.code = localStorage.getItem('code')
    }

    // ShareTrace.init({
    //   appkey: '1d50ed8fb7e598ad', //分配的appkey
    //   param: _this.code, //自定义参数，如果不设置默认会取url携带的参数"myKey=myValue",
    //   success: function () {
    //     _this.isShareTrace = true
    //     /*
    //     如果开启调起app功能建议在此处调用wakeUp()
    //   */
    //     //ShareTrace.wakeUp();
    //     /*
    //     如果要打开页面就自动下载，建议在此处调用download()方法，
    //     不过此处调用可能会降低成功率，建议在点击方法中触发download()方法
    //   */
    //     //ShareTrace.download();
    //   }
    // })
  },
  methods: {
    goToWebApp () {
      if (window.location.host == 'be116.app') {
        window.open('https://m.be116.com/#/')
      } else if (window.location.host == 'be771.app') {
        window.open('https://m.be771.com/#/')
      } else {
        window.open(this.webUrl)
      }
    },
    goTosetting () {
      this.$router.push({ path: '/setting' })
    },
    getUrl () {
      appdownloadlink(this.yourPhone).then((res) => {
        if (res.data.status_code === 200) {
          Object.keys(res.data.link2).forEach((item) => {
            if (window.location.host == item) {
              this.url = res.data.link2[item]
            }
          })
        }
      })
    },
    downText () {
      // if (this.isShareTrace) {
      //   let _this = this
      //   ShareTrace.preDownload(function () {
      //     window.location.href = _this.url + '?' + _this.code
      //   })
      // } else {
      if (this.yourPhone === 1) {
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = this.url
        link.setAttribute('download', '贝宝体育') //指定下载后的文件名，防跳转
        document.body.appendChild(link)
        link.click()
      } else {
        window.location.href = this.url
      }
      // }
    },
    speed () {
      speed().then((res) => {
        if (res.data.status_code === 200) {
          this.webUrl = res.data.res[0]['domain_url']
        }
      })
    },
    getProgressandio () {
      this.downText()
      //进度条恢复为 0
      this.currentRate = 0
      //按钮置灰 不可点击
      this.isDisabled = true
      //发起请求
      this.$axios({
        //下载文件的远端地址。
        url: this.url,
        //请求方式 get post。
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*'
        },

        onDownloadProgress: (progressEvent) => {
          let progressBar = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )
          if (progressBar >= 99) {
            setTimeout(() => {
              this.currentRate = 99
            }, 5000)
          } else {
            this.currentRate = progressBar
            this.downapptitle = '正在下载...'
          }
        }
      }).then((res) => {
        //当前定义res.message 出现的时候说明后端文件返回出现了问题 不然应该直接是个文件流
        if (res.message) {
          this.downapptitle = '下载失败'
          this.isDisabled = false
          return
        }
        this.downapptitle = '解析中...'
        setTimeout(() => {
          //编译文件完成后，进度条展示为100%100
          this.currentRate = 100
          //下载完成 可以重新点击按钮下载
          this.isDisabled = false
          this.downapptitle = '立即下载'
        }, 5000)
      })
    },
    getProgressios () {
      //进度条恢复为 0
      this.currentRate = 0
      //按钮置灰 不可点击
      this.isDisabled = true
      //发起请求
      this.$axios({
        //下载文件的远端地址。
        url: this.iosdownipa,
        //请求方式 get post。
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*'
        },

        onDownloadProgress: (progressEvent) => {
          let progressBar = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )
          if (progressBar >= 99) {
            setTimeout(() => {
              this.currentRate = 99
            }, 5000)
          } else {
            this.currentRate = progressBar
            this.downapptitle = '正在下载...'
          }
        }
      }).then((res) => {
        //当前定义res.message 出现的时候说明后端文件返回出现了问题 不然应该直接是个文件流
        if (res.message) {
          this.downapptitle = '下载失败'
          this.isDisabled = false
          return
        }
        this.downapptitle = '解析中...'
        setTimeout(() => {
          //编译文件完成后，进度条展示为100%100
          this.currentRate = 100
          //下载完成 可以重新点击按钮下载
          this.isDisabled = false
          this.downapptitle = '立即下载'
        }, 5000)
      })
    },
    getProgress () {
      console.log(this.yourPhone)
      if (this.yourPhone == 2) {
        window.location.href = this.url
        this.getProgressios()
        return
      } else if (this.yourPhone == 1) {
        this.getProgressandio()
      }
    }
    /*  iosDown () {
       window.location.href = 'itms-services:///?action=download-manifest&url=https://abtcloud.gz.bcebos.com/ipa/ipa.plist';
     } */
  }
}
</script>

<style scoped lang="less">
@import url("../assets/url.less");
.newDownMobile {
  width: 100vw;
  overflow: hidden;
  background: url("../assets/img/bg-img.png") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  .top_image {
    width: 100%;
    text-align: center;
    margin: 20px auto 10px;
    .img_all_logo {
      width: 250px;
      height: auto;
      margin-bottom: 0;
    }
  }
  .baodu {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    display: block;
    color: #b2987f;
  }
  .warningText_new {
    width: 100%;
    margin: 20px auto;
    font-size: 12px;
    font-family: PingFang SC;
    color: #000000;
    text-align: center;
    margin-bottom: 5px;
  }
  #progress {
    width: 100%;
    margin: 10px auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .load_pic {
      width: 315px;
      line-height: 45px;
      height: 45px;
      background: linear-gradient(270deg, #2c63e8, #6db9ff);
      // margin: 0 auto;
      margin-left: 10px;
      font-size: 16px;
      border-radius: 45px;
      color: #fff;
      position: relative;
    }
    #downText {
      font-size: 18px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: center;
      line-height: 45px;
      color: #fbfbfb;
    }
  }
  .href_text {
    width: 315px;
    border: 1px solid #356feb;
    height: 45px;
    font-size: 14px;
    color: #356feb;
    text-align: center;
    border-radius: 40px;
    line-height: 45px;
    margin: 0 auto;
  }
  .down_wrapper {
    height: 30px;
    width: 280px;
    margin: 20px auto 0;
    line-height: 30px;
    text-align: center;
    position: relative;
    .down_l_new {
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translate(-650%, -50%);
      width: 10px;
      height: 10px;
      background: url("../assets/img/icon_down.png") top center/cover no-repeat;
    }
    .box {
      font-size: 14px;
      margin: 0;
      display: block;
      color: #2f4367;
    }
  }
  .torial_step {
    display: block;
    width: 340px;
    margin: 0 auto 50px;
  }
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #d2b596;
  width: 80%;
}
.swiperImg {
  width: 280px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.van-swipe {
  width: 100vw;
  height: 310px;
  margin-top: 20px;
}

.new_all_logo {
  width: 290px;
  margin: 0 auto;
  img {
    width: 100%;
    min-height: 100px;
  }
}

.baodu-box {
  position: fixed;
  top: 52%;
  right: 0;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // height: 100vh;
  width: 100vw;
  z-index: 990;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
}
.cBox5 {
  width: 320px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  padding-bottom: 20px;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .popup-title {
    font-size: 18px;
    height: 70px;
    padding: 10px;
    line-height: 35px;
    color: #ffffff;
    font-family: PingFang SC;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: linear-gradient(270deg, #2c63e8, #6db9ff);
  }

  .popup-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -20px;
    background-color: #ffffff;
    padding: 10px 15px;
  }
  .popup-content-title {
    margin-top: 5px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #607198;
    line-height: 25px;
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC;
    height: 40px;
    font-weight: 500;
    width: calc(100%- 30px);
    line-height: 40px;
    margin: 0 15px;
    text-align: center;

    .btnfuzhi {
      width: 90%;
      height: 40px;
      line-height: 40px;
      background: linear-gradient(0deg, #2a6afd, #63c6ff);
      box-shadow: 2px 2px 3px 0px rgba(52, 123, 523, 0.46);
      color: #ffffff;
      border-radius: 8px;
    }
  }
}
</style>
