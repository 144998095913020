import { request } from './request'

export function appdownloadlink(apptype) {
  return request({
    method: 'POST',
    url: 'appdownloadlink',
    data: {
      apptype,
    },
  })
}
export function speed() {
  return request({
    method: 'GET',
    url: 'speed',
  })
}

export function dowmapp(url ,percentage,title) {
  return request({
    method: 'GET',
    url,
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      //progressEvent.loaded 下载文件的当前大小
      //progressEvent.total  下载文件的总大小 如果后端没有返回 请让他加上！
      let progressBar = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      )
      //接收进度为99%的时候需要留一点前端编译的时间
      if (progressBar >= 99) {
        percentage = 99
        title = '下载完成，文件正在编译。'
      } else {
        percentage = progressBar
        title = '正在下载，请稍等。'
      }
    }
  })
}
